import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import LandingPage from './container/client/LandingPage'
import AllProjects from './container/client/All-Project/AllProject'
import LoadingOverlay from 'react-loading-overlay'
import { FadeLoader } from 'react-spinners'
import { useHistory, useLocation } from 'react-router'
import Feeds from './container/service-provider/Feeds/feeds'
import Offerings from './container/service-provider/Offerings/Offering'
import { UserService } from './http-services/client/user'
import socket from './socket'
import Header from './components/shared/Header/Header'
import 'antd/dist/antd.css';
import SubmissionPreview from './container/client/SubmissionPreview/submission-preview'
import { useSelector, useDispatch } from 'react-redux'
import { updateZhxPrice, updateProfileType, updateToggleProfile } from './slices/tokenSlice'
import PrivacyPolicy from './container/PrivacyPolicy/PrivacyPolicy'
import Footer from './components/shared/Footer/Footer'
const DetailRequest = lazy(() => import('./container/client/detail-request/DetailRequest'))
const ProjectRequestForm = lazy(() => import('./container/client/CreateRequest/project-request'))
const BlockExplorer = lazy(() => import('./container/block-explorer/BlockExplorer'))
// const SubmissionDetails = lazy(() => import('./container/client/Submission-Details/SubmissionDetails'))
const S_details_client = lazy(() => import('./container/client/Submission-Details/S_details_client'))
const S_details_serviceProvider = lazy(() => import('./container/client/Submission-Details/S_details_serviceProvider'))
const HowToBuy = lazy(() => import('./container/buyZHX/HowToBuy'))
const HowItWorks = lazy(() => import('./container/client/HowItWorks/HowItWorks'))
const TermsAndConditions = lazy(() => import('./container/terms&condition/TermsAndConditions'))

function App() {
  const history = useHistory()
  const location = useLocation()
  const httpUser = new UserService()
  const profileType = useSelector((state) => state.profileType)
  const email = useSelector((state) => state.email)
  const [profileSwitched, setProfileSwitched] = useState(true)
  const dispatch = useDispatch()

  console.log('render app');
  const switchProfileType = async () => {
    dispatch(updateToggleProfile(true))
    setProfileSwitched(false)
    setTimeout(() => {
      if (!profileType || profileType === 'client') {
        dispatch(updateProfileType('service_provider'))
      } else {
        dispatch(updateProfileType('client'))
      }
      setProfileSwitched(true)
      history.push({ pathname: '/', state: { toggleProfile: true } })
    }, 1500)
  }
  useEffect(() => {
    if (location.state?.toggleProfile)
      dispatch(updateToggleProfile(false))
  }, [location])

  useEffect(async () => {
    const priceResp = await httpUser.getZHXPrice()
    console.log('priceResp', priceResp)
    if (priceResp?.status === 200)
      dispatch(updateZhxPrice(priceResp?.data?.result?.toFixed(5)))
  }, [])

  return (
    <>
      <LoadingOverlay
        active={!profileSwitched}
        spinner={<FadeLoader color='white' />}
      >
        <div
          className='container-fluid'
          style={{
            backgroundColor: '#E5E5E5',
            padding: '0px',
          }}
        >
          <Header
            switchProfileType={switchProfileType}
          />
          <Switch>
            <Route
              path='/projects'
              exact
              component={AllProjects}
            />

            <Route
              path='/projects/submission/:submissionId/preview'
              exact
              component={SubmissionPreview}
            />
            <Route
              path='/projects/:requestId/submission/:submissionId/details'
              exact
              component={(props) =>
                <Suspense fallback={
                  <div className='col-12 d-flex justify-content-center align-items-center bg-white'>
                    <FadeLoader />
                  </div>}>
                  <S_details_client
                    props={props}
                  />
                </Suspense>
              }
            />
            <Route
              path='/projects/:id/details'
              exact
              component={(props) =>
                <Suspense fallback={
                  <div className='col-12 d-flex justify-content-center align-items-center bg-white'>
                    <FadeLoader />
                  </div>}>
                  <DetailRequest
                    {...props}

                  />
                </Suspense>
              }
            />
            <Route
              path='/projects/new'
              exact
              component={() =>
                <Suspense fallback={
                  <div className='col-12 d-flex justify-content-center align-items-center bg-white'>
                    <FadeLoader />
                  </div>}>
                  <ProjectRequestForm
                  />
                </Suspense>
              }
            />

            <Route path='/feeds' exact component={Feeds} />
            <Route path='/offering' exact component={Offerings} />
            <Route path='/blockexplorer' exact component={() =>
              <Suspense fallback={
                <div
                  className='col-12 d-flex justify-content-center align-items-center bg-white'>
                  <FadeLoader />
                </div>}>
                <BlockExplorer />
              </Suspense>
            } />
            <Route path='/buy' exact component={() =>
              <Suspense fallback={
                <div
                  className='col-12 d-flex justify-content-center align-items-center bg-white'>
                  <FadeLoader />
                </div>}>
                <HowToBuy />
              </Suspense>
            } />
            <Route
              path='/feeds/:id/details'
              exact
              component={(props) =>
                <Suspense fallback={
                  <div className='col-12 d-flex justify-content-center align-items-center bg-white'>
                    <FadeLoader />
                  </div>}>
                  <DetailRequest
                    {...props}

                  />
                </Suspense>
              }
            />
            <Route
              path='/offer/submission/:submissionId/project/:requestId/details'
              exact
              component={(props) => (
                <Suspense fallback={
                  <div className='col-12 d-flex justify-content-center align-items-center bg-white'>
                    <FadeLoader />
                  </div>}>
                  <S_details_serviceProvider
                    props={props}
                  />
                </Suspense>
              )}
            />

            <Route
              path='/terms-and-conditions'
              exact
              component={() => <Suspense fallback={<div className='col-12 d-flex justify-content-center align-items-center bg-white'><FadeLoader /></div>}>
                <TermsAndConditions />
              </Suspense>
              }
            />
            <Route
              path='/privacy-policy'
              exact
              component={() => <Suspense fallback={<div className='col-12 d-flex justify-content-center align-items-center bg-white'><FadeLoader /></div>}>
                <PrivacyPolicy />
                <Footer />
              </Suspense>
              }
            />
          </Switch>
        </div>

        <Switch>
          <Route path='/' exact component={LandingPage} />
          <Route path='/how-it-works' exact component={() =>
            <Suspense fallback={
              <div className='col-12 d-flex justify-content-center align-items-center bg-white'>
                <FadeLoader />
              </div>}>
              <HowItWorks profileType={profileType} />
            </Suspense>
          } />
        </Switch>
        {/* <Footer /> */}
      </LoadingOverlay>
    </>
  )
}

export default App
