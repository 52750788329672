import React from 'react'
import './banner.css'
import croudSourceImg from '../../../assests/images/croudSourcingImg.webp'
import ZeroHex from '../../../assests/images/logo.svg'

const Banner = () => {
  return (
    <div className='container-fluid'>
      <div className='row justify-content-between'>
        <div className='col-lg-6 px-4 px-lg-5 pt-5 mt-5'>
          <p className='heading mt-lg-5'>
            The first crowdsourcing marketplace with blockchain
          </p>
          <p className='sub-text'>
            Our goal is to create economic opportunities that help empower
            communities. We are a marketplace that enables buying and selling of
            blockchain and internet services through smart contracts without
            human intervention.
          </p>
          <a
            href='https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x48ec837634f5e4ab2f111ad05b3c491b9c8cfcc8'
            className='btn btn-buy'
            target='_blank'
          >
            <img
              src={ZeroHex}
              style={{ height: '25px', width: '25px', marginRight: '10px' }}
            />
            Buy ZHX
          </a>
          <p className='note'>
            <strong>*Note:</strong> Kindly ensure you are buying the right ZHX
            token by rechecking the main smart contract below:{' '}
            <a
              href='https://bscscan.com/token/0x48Ec837634F5e4AB2f111Ad05B3C491B9C8cFCC8'
              target='_blank'
              style={{ overflowWrap: 'break-word' }}
            >
              0x48Ec837634F5e4AB2f111Ad05B3C491B9C8cFCC8
            </a>
          </p>
        </div>
        <div className='col-12 col-lg-5 pt-5 overflow-hidden'>
          <img src={croudSourceImg} className='croudSourceImg mt-3' />
        </div>
      </div>
    </div>
  )
}

export default Banner
