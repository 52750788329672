import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./services.carousel.css";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router";
import Slide1 from "../../../assests/images/slider1.webp";
import Slide2 from "../../../assests/images/slider2.webp";
import Slide3 from "../../../assests/images/slider3.webp";
import Slide4 from "../../../assests/images/slider4.webp";
import Slide5 from "../../../assests/images/slider5.webp";
import ArrowRight from "../../../assests/images/arrow-right.svg";
import ArrowLeft from "../../../assests/images/arrow-left.svg";

const ServicesCarousel = () => {
  const history = useHistory();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      partialVisibilityGutter: 20
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: { max: 1024, min: 624 },
      items: 3,
      partialVisibilityGutter: 40
    },
    mobile: {
      breakpoint: { max: 624, min: 0 },
      items: 1,
      partialVisibilityGutter: 100
    },
  };

  const CustomRight = ({ onClick }) => (
    <button className="arrow right d-none d-lg-block" onClick={onClick} >
      <img src={ArrowRight} />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="arrow right-10 d-none d-lg-block" onClick={onClick} >
      <img src={ArrowLeft} />
    </button>
  );

  const handleCardClick = () => {
    history.push("/projects/new");
  };
  return (
    <Carousel
      responsive={responsive}
      removeArrowOnDeviceType={["mobile"]}
      draggable={false}
      customRightArrow={<CustomRight />}
      customLeftArrow={<CustomLeft />}
      className="pb-5 container-fluid "
      partialVisible
    >
      <Card className="slide-card" onClick={handleCardClick}>
        <Card.Img variant="top" src={Slide1} className="slide-card-img" />
        <Card.Body>
          <Card.Title className="slide-card-title">
            Website Development
          </Card.Title>
          <Card.Text className="slide-card-body">
            Enhance the success of your business by specialist web development
            services.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className="slide-card" onClick={handleCardClick}>
        <Card.Img variant="top" src={Slide2} className="slide-card-img" />
        <Card.Body>
          <Card.Title className="slide-card-title">
            Mobile Application
          </Card.Title>
          <Card.Text className="slide-card-body">
            Tap into a wider audience through incredible high-quality,
            cross-platform apps, ensuring portability and ease of use.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className="slide-card" onClick={handleCardClick}>
        <Card.Img variant="top" src={Slide3} className="slide-card-img" />
        <Card.Body>
          <Card.Title className="slide-card-title">
            Product / UI UX Designer
          </Card.Title>
          <Card.Text className="slide-card-body">
            Bring your ideas to life through modern and trendy designs and
            bridge the gap between your business and consumers.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className="slide-card" onClick={handleCardClick}>
        <Card.Img variant="top" src={Slide4} className="slide-card-img" />
        <Card.Body>
          <Card.Title className="slide-card-title">Smart Contract</Card.Title>
          <Card.Text className="slide-card-body">
            Build smooth automation applications that run on a decentralized
            network.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className="slide-card" onClick={handleCardClick}>
        <Card.Img variant="top" src={Slide5} className="slide-card-img" />
        <Card.Body>
          <Card.Title className="slide-card-title">Other Services</Card.Title>
          <Card.Text className="slide-card-body">
            If you have an idea, share with us. Some of our service providers
            can give you a full solution
          </Card.Text>
        </Card.Body>
      </Card>
    </Carousel>
  );
};

export default ServicesCarousel;
