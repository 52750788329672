import axios from 'axios';
import config from '../../config/config.json';
export class ProjectRequestService {
  createRequest = (data, authToken) => {
    try {
      let resp = axios.post(
        `${config.apiUrl}/requests/create_request`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': authToken
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in createRequest Http request', error);
      return null;
    }
  };
  showAllRequest = (isServiceProvider, authToken,email,wallet) => {
    try {
      let resp = axios.get(
        `${config.apiUrl}/requests/show_all_requests/${wallet ? wallet : email}/${isServiceProvider}`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in showAllRequest Http request', error);
      return null;
    }
  };
  getRequest = (requestId, isServiceProvider, authToken) => {
    try {
      let resp = axios.get(
        `${config.apiUrl}/requests/show_request_details/${requestId}/${isServiceProvider}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in getRequest Http request', error);
      return null;
    }
  };

  markProject = (data, authToken) => {
    try {
      let resp = axios.put(
        `${config.apiUrl}/requests/mark_request_close`,
        {
          requestId: data
        },
        {
          headers: {
            'x-access-token': authToken,
          },
        }
      );
      return resp;
    } catch (error) {
      console.log('Error in markProject Http request', error);
      return null;
    }
  };

  showAllOffering = ( authToken) => {
    try {
      let resp = axios.get(`${config.apiUrl}/requests/show_offerings`, {
        headers: {
          'x-access-token': authToken,
        },
      });
      return resp;
    } catch (error) {
      console.log('Error in showAllOffering Http request', error);
      return null;
    }
  };
}
