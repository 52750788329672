import React from "react";
import "./Advertisement.css";
import benefitsImg from "../../../assests/images/benefits img.webp";
import bidsIcon from "../../../assests/images/iconspace_Bids.svg";
import moneyIcon from "../../../assests/images/iconspace_Money hand.svg";
import trophyIcon from "../../../assests/images/iconspace_trophy.svg";
import diamondIcon from "../../../assests/images/iconspace_Diamond.svg";

const Advertisement = () => {
  return (
    <div className="row pt-5">
      <div className="col-md-5 d-flex flex-column mt-n5">
        <img src={benefitsImg} className="benefitImg align-self-center" />

        <p className="benefitImgHeading">What sets us apart from the rest? </p>
        <p className="benefitImgPara">
          We aim to assist you completely as you transform your ideas into
          reality. Find the right professionals from profiles catered precisely
          according to your needs by selecting the submission that matches your
          requirement criteria.
          {/* <br />
          <br /> Find the right professionals from profiles catered precisely
          according to your needs by selecting the submission that matches your
          requirement criteria. */}
        </p>
        <br />
      </div>
      <div className="col-md-7 section mt-3">
        <div className="row" style={{ marginBottom: "70px" }}>
          <div className="col-md-6">
            <img src={bidsIcon} />
            <p className="advertisementHeading">Bids come to you</p>
            <p className="advertisementPara">
              Compare the submissions that are presented to you and have the
              freedom to select the best one based completely on your
              preferences.
            </p>
          </div>

          <div className="col-md-6">
            <img src={moneyIcon} />

            <p className="advertisementHeading">Very low fee </p>
            <p className="advertisementPara">
              <span style={{ fontWeight: "bold", color: "#0084ff" }}>
                As low as 10% &nbsp;
              </span>
              payment processing and administration fee on all payments to
              freelancers.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={diamondIcon} />
            <p className="advertisementHeading">Pay for quality</p>
            <p className="advertisementPara">
              Pay for work only when it has been completed and you are 100%
              satisfied. No hidden charges, no pesky loopholes.
            </p>
          </div>

          <div className="col-md-6">
            <img src={trophyIcon} />

            <p className="advertisementHeading">Professionality </p>
            <p className="advertisementPara">
              Everything you need in order to help your business grow is just a
              click away.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
