import React from 'react'
import { useHistory } from 'react-router'
import { Card } from 'react-bootstrap'
import './feed.css'
import moment from 'moment'
import * as utils from '../../../utils/utils'
import { useSelector } from 'react-redux'

const Feed = ({ feed }) => {
  const history = useHistory()
  const zhxPrice = useSelector(state => state.zhxPrice)
  const handleFeedClick = () => {
    if (feed.status === 'open') {
      let path = `/feeds/${feed.id}/details`
      history.push(path)
    }
  }
  return (
    <Card className='feed-card'>
      <Card.Header>
        <Card.Title>
          <div className='row'>
            <div className='col-md-9'>
              <p className='feed-title' onClick={handleFeedClick}>
                {feed.title}
              </p>
              <p className='feed-normal'>
                Posted {moment(feed.timestamp).format('DD MMMM yyyy')}
              </p>
            </div>

            <div className='col-md-3'>
              <p className='feed-amount'>
                {utils.maskValue(feed.total_budget)} ZHX
              </p>
              <p className='feed-approx-amount'>
                ~ ${utils.maskValue(zhxPrice * feed.total_budget)}
              </p>
            </div>
          </div>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <div className='row'>
            <div className='col-md-3'>
              <p className='feed-normal'>
                Client:
                <span style={{ marginLeft: '20px' }}>
                  <strong>
                    {feed.first_name === 'null' ? '-' : feed.first_name}{' '}
                    {feed.last_name === 'null' ? '-' : feed.last_name}
                  </strong>
                </span>
              </p>
            </div>
            <div className='col-md-6'>
              <p className='feed-normal'>
                Deadline Submission:
                <span style={{ marginLeft: '20px' }}>
                  <strong>
                    {moment(feed.submission_deadline).format('DD / MM / yyyy')}
                  </strong>
                </span>
              </p>
            </div>
          </div>
        </Card.Text>
      </Card.Body>
      <Card.Footer className='feed-footer'>
        <p className='feed-normal'>
          {feed.description.length > 400
            ? feed.description.substring(0, 400) + '...'
            : feed.description}
        </p>
      </Card.Footer>
    </Card>
  )
}

export default Feed
