import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import './modals.css'
import dummyPic from '../../../assests/images/profile-placeholder.webp'
import addPic from '../../../assests/images/add-pic.svg'
import { UserService } from '../../../http-services/client/user'
import * as utils from '../../../utils/utils'
import moment from 'moment'
import $ from 'jquery'
import { PulseLoader } from 'react-spinners'
import { Link } from 'react-router-dom'
import config from '../../../config/config.json'
import { useSelector } from 'react-redux'

const ProfilePreviewModal = props => {
  const authToken = useSelector(state => state.authToken)
  const profileType = useSelector(state => state.profileType)
  const profile = useSelector(state => state.profile)
  const wallet = useSelector(state => state.wallet)
  const email = useSelector(state => state.email)

  const [showEditProfileForm, setShowEditProfileForm] = useState(false)
  const [firstName, setFirstName] = useState(
    profile.first_name === null ? 'N/A' : profile.first_name
  )
  const [lastName, setLastName] = useState(
    profile.last_name === null ? 'N/A' : profile.last_name
  )
  const [designation, setDesignation] = useState(
    profile.designation === null ? 'N/A' : profile.designation
  )
  const [profilePic, setProfilePic] = useState(
    profile.user_image !== null ? config.apiUrl + profile.user_image : null
  )
  const [profileUpdated, setProfileUpdated] = useState(true)
  const toggleEditProfileForm = () => {
    setShowEditProfileForm(!showEditProfileForm)
  }
  const httpUser = new UserService()
  const onContinueClick = async () => {
    try {
      setProfileUpdated(false)
      let formData = new FormData()

      formData.append(
        email ? 'email' : 'metamaskAddress',
        email ? email : wallet
      )
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('designation', designation)
      formData.append('image', profilePic)

      if (authToken) {
        var response = await httpUser.updateUser(formData, authToken)
        if (response.data) {
          utils.showSuccessAlert(
            'Success',
            'Details updated successfully',
            'success'
          )
          setFirstName('')
          setLastName('')
          setDesignation('')
          setShowEditProfileForm(false)
          props.toggleProfilePreviewModal()
          setProfileUpdated(true)
        }
      }
    } catch (error) {
      utils.showErrorAlert('Oops', 'Something went wrong', 'error')
      console.log('ERR: ', error.message)
      setProfileUpdated(true)
    }
  }
  const handleFileChange = e => {
    setProfilePic(e.target.files[0])
  }
  const handleImagUpload = () => {
    $('#files').trigger('click')
  }
  const renderEditProfileForm = () => {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-md-12' style={{ padding: '5px' }}>
            <input
              type='file'
              style={{ display: 'none' }}
              id='files'
              onChange={handleFileChange}
              accept='image/jpeg,image/jpg, image/png'
            />
            <div
              style={{
                height: '60px',
                width: '60px',
                borderRadius: '100px',
                backgroundColor: '#E0E0E0',
                display: 'inline-block',
                position: 'relative',
              }}
            >
              <img
                src={
                  profilePic === null
                    ? addPic
                    : profilePic.size === undefined
                    ? profilePic
                    : URL.createObjectURL(profilePic)
                }
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginTop: '10px',
                  height: '40px',
                  width: '40px',
                  borderRadius: '100px',
                }}
                onClick={handleImagUpload}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6' style={{ padding: '5px' }}>
            <input
              type='text'
              className='form-control'
              name='firstName'
              placeholder='First Name*'
              value={firstName === 'N/A' ? '' : firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className='col-md-6' style={{ padding: '5px' }}>
            <input
              type='text'
              className='form-control'
              name='lastName'
              value={lastName === 'N/A' ? '' : lastName}
              placeholder='Last Name*'
              onChange={e => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12' style={{ padding: '5px' }}>
            <input
              type='text'
              className='form-control'
              name='designation'
              value={designation === 'N/A' ? '' : designation}
              placeholder='Designation'
              onChange={e => setDesignation(e.target.value)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <button
              className='btn btn-continue-profile'
              onClick={onContinueClick}
            >
              {profileUpdated === false ? (
                <PulseLoader size={10} color='white' />
              ) : (
                'Continue'
              )}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const renderUserProfile = () => {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-md-12' style={{ padding: '0px' }}>
            <img
              style={{
                width: '47px',
                height: '47px',
                borderRadius: '100px',
                marginRight: '10px',
              }}
              src={profilePic === null ? dummyPic : profilePic}
              className='img-profile'
            />
            <p className='profile-name'>{`${
              profile.first_name ? profile.first_name : 'N/A'
            } ${profile.last_name ? profile.last_name : 'N/A'}`}</p>
            <div className='d-flex flex-column'>
              <p className='profile-email'>{profile.email}</p>
              <p className='profile-email'>{wallet}</p>
            </div>
          </div>
        </div>
        <Link
          className='nav-link primary-font-color active-lnk'
          style={{ fontSize: '13px' }}
          to='#'
          onClick={() => {
            props.toggleProfileType()
          }}
        >
          Switch to
          {profileType === null || profileType === 'client'
            ? ' Service Provider'
            : ' Client'}
          <span className='sr-only'></span>
        </Link>
      </div>
    )
  }
  const renderUserProfileData = () => {
    return (
      <table className='table profile-tbl'>
        <tbody>
          <tr>
            <td className='profile-td-left'>Join Date</td>
            <td className='profile-td-right'>
              {profile !== null
                ? moment(profile.created_time).format('DD MMMM yyyy')
                : 0}
            </td>
          </tr>
          <tr>
            <td className='profile-td-left'>Total Project</td>
            <td className='profile-td-right'>
              {/* {profile !== null ? profile.total_requests : 0} */}
              N/A
            </td>
          </tr>
          <tr>
            <td className='profile-td-left'>Project Done</td>
            <td className='profile-td-right'>
              {/* {profile !== null ? profile.total_accepted : 0} */}
              N/A
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
  return (
    <Modal
      show={props.showProfileModal}
      size='custom'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          props.toggleProfilePreviewModal()
        }}
      >
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Edit Profile
        </Modal.Title> */}
        {showEditProfileForm === true ? (
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Profile
          </Modal.Title>
        ) : (
          renderUserProfile()
        )}
      </Modal.Header>
      <Modal.Body>
        {showEditProfileForm === true
          ? renderEditProfileForm()
          : renderUserProfileData()}
      </Modal.Body>
      <Modal.Footer>
        {showEditProfileForm ? (
          <p className='edit-profile-modal-footer-text'>
            You can input your email to receive notification and other
            information related your project request.
          </p>
        ) : (
          <div className='w-100'>
            <button
              className='btn btn-edit-profile btn-edit-profile-color'
              onClick={toggleEditProfileForm}
            >
              Edit Profile
            </button>
            <button
              className='btn btn-edit-profile mt-3 border-danger text-danger'
              onClick={props.logout}
            >
              Logout
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ProfilePreviewModal
