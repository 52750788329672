import React from "react";
import logoWhite from "../../../assests/images/footer-icon.svg";
import insta from "../../../assests/images/insta.svg";
import fb from "../../../assests/images/facebook.svg";
import twitter from "../../../assests/images/twitter.svg";
// import linkedin from "../../../assests/images/linkedin.svg";
import medium from "../../../assests/images/medium.svg";
import { FaTelegramPlane } from "react-icons/fa";
import { FaReddit } from "react-icons/fa";

import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    // <div className="container-fluid p-0">
    <div id="footer">
      <div className="row" >
        <div className="col-md-1 text-center text-lg-left">
          <img src={logoWhite} className="footer-logo" />
        </div>
        <div className="col-lg-8 col-md-7  text-center text-lg-left">
          <div className="d-flex flex-column flex-xl-row align-items-center justify-content-around">
            <Link to="/how-it-works" className="links py-1 py-xl-0">
              HOW IT WORKS
            </Link>
            <Link to="/" className="links py-1 py-xl-0">
              Have Any Questions? ask@zerohex.market
            </Link>
            <Link to="/terms-and-conditions" className="links py-1 py-xl-0">
              TERMS & CONDITIONS
            </Link>
            <Link to="/privacy-policy" className="links py-1 py-xl-0">
              PRIVACY POLICY
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 social-icons justify-content-lg-end justify-content-center pr-lg-4">
          <a href="https://www.facebook.com/Zer0Hex" target="_blank">
            <img src={fb} className='f-icons' />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://instagram.com/zero_hex?utm_medium=copy_link" target="_blank">
            <img src={insta} className='f-icons' />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://www.reddit.com/u/Zerohex-market?utm_medium=android_app&utm_source=share" target="_blank">
            <FaReddit className="f-icons text-white" />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://zerohex.medium.com/" target="_blank">
            <img src={medium} className='f-icons' />
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://twitter.com/ZeroHexMarket" target="_blank">
            <img src={twitter} className='f-icons'/>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://t.me/zerohexmarket" target="_blank">
            <FaTelegramPlane className="f-icons text-white" />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="seprator my-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-1 col-md-10 p-0">
          <center>
            <p className="footer-para">
              Copyright © 2021 All Rights Reserved. ZeroHex is a community run
              marketplace. We do not take responsibility for individual buyer's
              and seller's risk. Please assess all risks before interacting with
              the smart contract. It is a community based experimental project.
            </p>
          </center>
        </div>
      </div>
    </div>
    // </div>


  );
};

export default Footer;
