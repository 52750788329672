import React from "react";
import "./PostingBanner.css";
import { useHistory } from "react-router";
const PostingBanner = () => {
  const history = useHistory();
  const onGetStartedClick = () => {
    history.push("/projects");
  };
  return (
    // <div className="container" style={{ marginBottom: "50px" }}>
    <div className="posting-banner d-flex flex-column align-items-center" style={{ marginBottom: "50px" }}>
      <div className="row">
        <div className="col-md-12">
          <p className="banner-para">
            Simply post your business plan and receive competitive bids from
            freelancers within minutes.
          </p>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <div className="button" onClick={onGetStartedClick}>
            <p className="button-text">Get Started</p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default PostingBanner;
