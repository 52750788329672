import React, { useState, useEffect } from "react";

const ProjectStatus = ({ status }) => {
  const [color, setColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [text, setText] = useState(null);

  useEffect(() => {
    if (status === "open") {
      setColor("#0A9620");
      setBackgroundColor("rgba(10, 150, 32, 0.1)");
      setText("Status: Open");
    } else {
      setColor("#EB5757");
      setBackgroundColor("rgba(235, 87, 87, 0.1)");
      setText("Status: Closed");
    }
  }, [status]);
  return (
    <div
      className="project-status"
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <p
        className="project-status-text"
        style={{
          color: color,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default ProjectStatus;
