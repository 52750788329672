import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Header.css'
import logo from '../../../assests/images/logo.svg'
import connectedIndicator from '../../../assests/images/connected-indicator.svg'
import { Drawer } from 'antd'
import profilePic from '../../../assests/images/profile-placeholder.webp'
import editProfileIcon from '../../../assests/images/edit-profile-icon.svg'
import submission from '../../../assests/images/business-proposal.svg'
import submissionAccepted from '../../../assests/images/contract.svg'
import revision from '../../../assests/images/revision.svg'
import revisionAccepted from '../../../assests/images/accepted.svg'
import revisionrejected from '../../../assests/images/reject.svg'
import newFile from '../../../assests/images/uploaded.svg'
import submissionCompleted from '../../../assests/images/checked.svg'
import chat from '../../../assests/images/chat.svg'
import * as utils from '../../../utils/utils.js'
import EditProfileModal from '../modals/edit-profile-modal.jsx'
import { ClipLoader } from 'react-spinners'
import { Dropdown } from 'react-bootstrap'
import config from '../../../config/config.json'
import { BsBell } from 'react-icons/bs'
import { UserService } from '../../../http-services/client/user'
import Dot from '../dot/Dot'
import { useHistory } from 'react-router'
import moment from 'moment'
import SignupModal from '../modals/signupModal'
import ProfilePreviewModal from '../modals/profile-preview-modal'
import { useSelector, useDispatch } from 'react-redux'
import {
  updateAuthToken,
  updateProfile,
  updateZhxTokens,
  updateWalletAdd,
  reset,
  updateProfileType,
} from '../../../slices/tokenSlice'
import LoginModal from '../modals/loginModal'
import Web3 from 'web3'
import socket from '../../../socket'

const Header = ({ switchProfileType }) => {
  const history = useHistory()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [showSignupModal, setShowSignupModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)
  const [notifications, setNotifications] = useState()
  const [numberOfNotifications, setNumberOfNotifications] = useState()
  const [connected, setConnected] = useState(true)
  const [correctChain, setCorrectChain] = useState(true)
  const [accountsChanged, setAccountsChanged] = useState(false)
  const [account, setAccount] = useState()

  const tokens = useSelector(state => state.tokens)
  const profile = useSelector(state => state.profile)
  const profileType = useSelector(state => state.profileType)
  const authToken = useSelector(state => state.authToken)
  const wallet = useSelector(state => state.wallet)
  const email = useSelector(state => state.email)
  const dispatch = useDispatch()

  const httpUser = new UserService()

  const userChannel = new BroadcastChannel('zerohex')

  // const createCookie = () => {
  //   axios.get('http://localhost:4000', { withCredentials: true }).then(res => {
  //     console.log(res.data)
  //   })
  // }

  const switchChain = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }],
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x61',
                chainName: 'Binance Test Network',
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
                nativeCurrency: {
                  name: 'BNB',
                  symbol: 'BNB', // 2-6 characters long
                  decimals: 18,
                },
              },
            ],
          })
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }
  const handleAccountsChanged = async account => {
    if (email) {
      const resp = await utils.isWalletValid(email)
      if (resp.success) {
        const profile = await utils.getUserProfile(authToken)
        dispatch(updateProfile(profile))
        if (window.ethereum.chainId && window.ethereum.chainId !== '0x61') {
          console.log('not on test branch')
          setCorrectChain(false)
          return
        }
        const tokens = await utils.update_tokens_in_wallet(account)
        dispatch(updateWalletAdd(account))
        dispatch(updateZhxTokens(tokens))
        utils.showSuccessAlert('Connected', 'Wallet is connected now')
        setAccount()
      } else {
        setCorrectChain(true)
        dispatch(updateWalletAdd(null))
        dispatch(updateZhxTokens(0))
      }
    } else {
      await connect()
      if (window.location.pathname !== '/') history.push('/')
      dispatch(updateProfileType('client'))
    }
  }

  useEffect(() => {
    if (accountsChanged && account) {
      handleAccountsChanged(account)
      setAccountsChanged(false)
    }
  }, [accountsChanged, account])

  useEffect(async () => {
    if (wallet) {
      const tokens = await utils.update_tokens_in_wallet(wallet)
      dispatch(updateZhxTokens(tokens))
    }

    socket.io.on('reconnect', () => {
      console.log('reconnected')
      if (profile)
        socket.emit('join-room', {
          roomID: `notify${profile.user_id}`,
        })
    })

    if (connected && window.ethereum) {
      window.ethereum.on('accountsChanged', async accounts => {
        setAccountsChanged(true)
        setAccount(accounts[0])
      })

      window.ethereum.on('chainChanged', chainId => {
        console.log('chainId', chainId)
        if (wallet && chainId !== '0x61') {
          console.log('not on test branch')
          setCorrectChain(false)
        } else if (!correctChain) setCorrectChain(true)
        window.location.reload()
      })
    }

    userChannel.onmessage = param => {
      console.log(param.data, profileType)
      const currentTab = +sessionStorage.getItem('currentTab')
      if (param.data === 'LOG_OUT') {
        if (window.location.pathname !== '/') history.push('/')
        window.location.reload()
      } else if (param.data === 'PROFILE_CHANGE') {
        sessionStorage.removeItem('currentTab')
        if (window.location.pathname !== '/' && !currentTab) {
          if (!profileType || profileType === 'client') {
            dispatch(updateProfileType('service_provider'))
          } else {
            dispatch(updateProfileType('client'))
          }
          history.push('/')
        }
      }
    }
  }, [])

  useEffect(() => {
    const currentTab = +sessionStorage.getItem('currentTab')

    if (profileType && currentTab) {
      userChannel.postMessage('PROFILE_CHANGE')
    }
  }, [profileType])

  useEffect(() => {
    if (profile) {
      socket.emit(
        'get-notifications',
        { user_id: profile.user_id, profileType },
        numberOfNotifications => {
          console.log({ numberOfNotifications })
          setNumberOfNotifications(numberOfNotifications)
        }
      )
    }
  }, [profileType, profile])

  useEffect(async () => {
    if (
      wallet &&
      window.ethereum.chainId &&
      window.ethereum.chainId !== '0x61'
    ) {
      console.log('not on test branch')
      setCorrectChain(false)
    }

    if (authToken) {
      const metamaskOrEmail = email ? email : wallet
      const profile = await utils.getUserProfile(authToken, metamaskOrEmail)

      if (profile) {
        dispatch(updateProfile(profile))
        socket.emit('join-room', {
          roomID: `notify${profile.user_id}`,
        })

        socket.on('receive-notifications', resp => {
          console.log('numberOfNotifications', resp)
          if (profileType === resp.profileType)
            setNumberOfNotifications(resp.numberOfNotifications)
        })
        socket.on('user-online', CALLBACK => {
          CALLBACK('online')
        })
      }
    }
  }, [authToken, wallet, window.ethereum?.chainId])

  let tokenContract = null
  const get_tokens_in_wallet = async address => {
    let tokens = await tokenContract.methods.balanceOf(address).call()
    tokens = parseFloat(window.web3.utils.fromWei(tokens, 'ether'))
    return tokens
  }

  const connect = async () => {
    try {
      let isConnected = false
      if (window.ethereum) {
        console.log('Metamask connected')
        window.web3 = new Web3(window.ethereum)
        isConnected = true
        setConnected(false)
      } else if (window.web3) {
        console.log('Metamask connected')
        window.web3 = new Web3(window.web3.currentProvider)
        isConnected = true
        setConnected(false)
      } else {
        console.log('Metamask not installed')
        isConnected = false
        setConnected(true)
      }
      if (isConnected) {
        let isChainCorrect = true
        if (window.ethereum.chainId && window.ethereum.chainId !== '0x61') {
          console.log('not on test branch')
          setCorrectChain(false)
          isChainCorrect = false
        }
        const web3 = window.web3
        tokenContract = new web3.eth.Contract(
          config.tokenAbi,
          config.tokenContractAddr
        )
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        const tokens = isChainCorrect
          ? await get_tokens_in_wallet(accounts[0])
          : 0.0
        let resp
        if (email) {
          resp = utils.connectByEmail({
            email: email,
            walletAddress: accounts[0],
            zerohexToken: tokens,
            type: 'metamask',
          })
        } else {
          resp = await httpUser.connectMetamask({
            metamaskAddress: accounts[0],
            zerohexToken: tokens,
            type: 'metamask',
          })
        }
        if (!resp.success) {
          utils.showErrorAlert(
            'Registered already',
            'This wallet is associated with some other account. Please connect with other wallet address'
          )
          return false
        }
        if (!profile) {
          dispatch(updateProfileType('client'))
        }
        console.log(resp.token)
        dispatch(updateWalletAdd(accounts[0]))
        dispatch(updateZhxTokens(tokens))
        dispatch(updateAuthToken(resp.token))
        setConnected(true)
        return true
      } else {
        utils.showErrorAlert('Whoops...', 'Metamask is not installed')
        return false
      }
    } catch (error) {
      console.log('error while connecting metamask', error)
      setConnected(true)
      utils.showErrorAlert('Whoops...', 'Something went wrong.')
      return false
    }
  }

  const logout = () => {
    toggleProfilePreviewModal()
    localStorage.clear()
    dispatch(reset())
    setNumberOfNotifications()
    userChannel.postMessage('LOG_OUT')
  }

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  const toggleProfilePreviewModal = async () => {
    setShowProfileModal(!showProfileModal)
  }
  const toggleSignupModal = async () => {
    setShowSignupModal(!showSignupModal)
  }
  const toggleLoginModal = async () => {
    setShowLoginModal(!showLoginModal)
  }

  const toggleEditProfileModal = () => {
    setShowEditProfileModal(!showEditProfileModal)
  }

  const toggleProfileType = async () => {
    let resp = await utils.showConfirmationAlert(
      'Are you sure?',
      'You want to swtich your profile',
      'warning'
    )
    if (resp === true) {
      setShowProfileModal(false)
      await switchProfileType()
    }
  }

  const renderProfilePreview_Drawer = () => {
    return (
      <div className='container' style={{ marginBottom: '20px' }}>
        {showEditProfileModal === true ? (
          <EditProfileModal
            showEditProfileModal={showEditProfileModal}
            toggleEditProfileModal={toggleEditProfileModal}
            // getUser={getUser}
          />
        ) : (
          ''
        )}

        <div className='row' style={{ marginBottom: '20px' }}>
          <div className='col-sm-12 text-center'>
            <img src={connectedIndicator} />
            <span className='drawer-connected-txt'>Connected</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12' style={{ padding: '0px' }}>
            <img
              src={
                profile == null || profile.user_image === null
                  ? profilePic
                  : config.apiUrl + profile.user_image
              }
              style={{
                float: 'left',
                height: '47px',
                width: '47px',
                borderRadius: '100px',
                marginRight: '10px',
              }}
            />
            <p className='drawer-profile-name'>{`${
              profile && profile.first_name
            } ${profile && profile.last_name}`}</p>
            <span>
              <img
                src={editProfileIcon}
                className='drawer-profile-img'
                onClick={toggleEditProfileModal}
              />
            </span>
            <p className='drawer-profile-email'>{`${
              profile && profile.email
            }`}</p>
          </div>
        </div>
        <Link
          activeClassName='active-lnk'
          className='nav-link primary-font-color active-lnk'
          style={{ fontSize: '12px', textAlign: 'center' }}
          to='#'
          onClick={toggleProfileType}
        >
          Switch to
          {profileType === null || profileType === 'client'
            ? ' Service Provider'
            : ' Client'}
          <span className='sr-only'></span>
        </Link>
        <div className='row' style={{ marginBottom: '15px' }}>
          <div className='col-sm-12'>
            <span className='drawer-lbl'>ZHX Balance</span>
            <span className='drawer-amounts'>
              {utils.maskValue(tokens ? tokens : 0.0)} ZHX
            </span>
          </div>
        </div>

        <div className='row' style={{ marginBottom: '15px' }}>
          <div className='col-sm-12'>
            <span className='drawer-lbl'>Approx in USD</span>
            <span className='drawer-amounts'>$ 648</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12'>
            <label className='drawer-lbl-wallet'>Wallet Address</label>
            <p className='drawer-walletAddr'>{wallet}</p>
          </div>
        </div>
      </div>
    )
  }

  const renderWebNavLinks = () => {
    if (profileType === 'service_provider') {
      return (
        <ul className='navbar-nav mr-auto'>
          <li className='nav-item'>
            <NavLink
              to='/feeds'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Feeds <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/offering'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Offering <span className='sr-only'></span>
            </NavLink>
          </li>

          <li className='nav-item'>
            <NavLink
              to='/blockexplorer'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Block Explorer <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/buy'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              How to Buy ZHX <span className='sr-only'></span>
            </NavLink>
          </li>
        </ul>
      )
    } else {
      return (
        <ul className='navbar-nav mr-auto'>
          <li className='nav-item'>
            <NavLink
              to='/projects'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              My projects <span className='sr-only'></span>
            </NavLink>
          </li>

          <li className='nav-item'>
            <NavLink
              to='/blockexplorer'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Block Explorer <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/buy'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              How to Buy ZHX <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <Link
              className='btn btn-primary postBtnHeader p-2'
              to='/projects/new'
            >
              <p className='m-0'>Post New Request</p>
            </Link>
          </li>
        </ul>
      )
    }
  }

  const renderDrawerLinks = () => {
    if (profileType === 'client') {
      return (
        <ul className='navbar-nav mr-auto' style={{ textAlign: 'center' }}>
          <li className='nav-item'>
            <NavLink
              to='/projects'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              My projects <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/buy'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              How to Buy ZHX <span className='sr-only'></span>
            </NavLink>
          </li>

          <li className='nav-item'>
            <NavLink
              to='/blockexplorer'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Block Explorer <span className='sr-only'></span>
            </NavLink>
          </li>

          <li className='nav-item'>
            <Link className='btn btn-primary postBtnHeader' to='/projects/new'>
              <p className='btnPostTxtHeader m-0'>Post New Request</p>
            </Link>
          </li>
        </ul>
      )
    } else {
      return (
        <ul className='navbar-nav mr-auto' style={{ textAlign: 'center' }}>
          <li className='nav-item'>
            <NavLink
              to='/feeds'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Feeds <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/blockexplorer'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Block Explorer <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/offering'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              Offering <span className='sr-only'></span>
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink
              to='/buy'
              activeClassName='active-lnk'
              className='nav-link primary-font-color'
              aria-disabled='true'
            >
              How to Buy ZHX <span className='sr-only'></span>
            </NavLink>
          </li>
        </ul>
      )
    }
  }

  return (
    <>
      {showProfileModal === true ? (
        <ProfilePreviewModal
          showProfileModal={showProfileModal}
          toggleProfilePreviewModal={toggleProfilePreviewModal}
          toggleProfileType={toggleProfileType}
          logout={logout}
        />
      ) : (
        ''
      )}

      <div className='container-fluid' style={{ padding: '0px' }}>
        <Drawer
          placement='right'
          closable={true}
          visible={drawerVisible}
          onClose={toggleDrawer}
          width='260'
        >
          {wallet ? renderProfilePreview_Drawer() : ''}
          {renderDrawerLinks()}
          {profile ? (
            <button
              className='btn btn-edit-profile mt-3 border-danger text-danger'
              onClick={logout}
            >
              Logout
            </button>
          ) : (
            ''
          )}
        </Drawer>

        <nav className='navbar navbar-expand-lg navbar-light navbar-col py-4 py-lg-3 px-lg-5 flex-nowrap'>
          <Link to='/' className='navbar-brand ml-lg-2'>
            <img src={logo} width='36px' height='36px' />
          </Link>

          {wallet ? (
            <div className='amount-btn-mobile d-flex align-items-center px-2 d-lg-none '>
              <img src={logo} className='amountIcon-mobile' />
              <p className='amountBtnTxt-mobile'>
                {utils.maskValue(tokens ? tokens : 0.0)}
              </p>
            </div>
          ) : (
            <div
              className='meta-mask-mobile d-lg-none d-flex p-1 align-items-center'
              onClick={async () => {
                if (email || wallet) return
                // toggleProfilePreviewModal()
                else toggleLoginModal()
              }}
            >
              {/* <img src={metaMaskIcon} className='metamaskIcon-mobile' />
              Connect Metamask */}
              {email || wallet ? (
                <img
                  src={
                    profile == null || profile.user_image === null
                      ? profilePic
                      : config.apiUrl + profile.user_image
                  }
                  alt='dp'
                  className='profilePic rounded-circle'
                />
              ) : (
                'Login / Sign up'
              )}
            </div> //mobile
          )}

          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            {renderWebNavLinks()}
            <a
              className='amount btn-buy-header p-2 d-flex mx-3'
              href='https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x48ec837634f5e4ab2f111ad05b3c491b9c8cfcc8'
              target='_blank'
              style={{
                backgroundColor: 'rgb(60, 120, 216, 0.2)',
                cursor: 'pointer',
              }}
            >
              <img src={logo} className='zinIcon mr-2' />
              <p className='amountBtnText m-0'>Buy ZHX</p>
            </a>
            <div className='amount d-flex p-2'>
              <img src={logo} className='zinIcon mr-2' />
              <p
                className='amountBtnText m-0'
                // onClick={createCookie}
              >
                {utils.maskValue(tokens ? tokens : 0.0)}
              </p>
            </div>
          </div>
          <div className='d-flex'>
            <Dropdown
              className='ml-4'
              onClick={async () => {
                if (authToken) {
                  setNumberOfNotifications()
                  setNotifications()
                  if (email || wallet) {
                    if (profile) {
                      const noti = await httpUser.getNotifications(
                        authToken,
                        profileType
                      )
                      setNotifications(noti)
                      socket.emit(
                        'mark-notifications-read',
                        { user_id: profile.user_id, profileType },
                        resp => {
                          setNumberOfNotifications(resp)
                        }
                      )
                    }
                  }
                }
              }}
              // onBlur={async () => {
              //   if (authToken) {
              //     console.log('close notifications')
              //     socket.emit(
              //       'mark-notifications-read',
              //       { user_id: profile.user_id, profileType },
              //       resp => {
              //         setNumberOfNotifications(resp)
              //       }
              //     )
              //   }
              // }}
            >
              <Dropdown.Toggle
                disabled={!authToken ? true : false}
                className='bg-white rounded-circle notification-btn'
              >
                {numberOfNotifications ? (
                  <Dot numberOfNotifications={numberOfNotifications} />
                ) : (
                  ''
                )}
                <BsBell className='text-lightBlue' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='p-0 w-notification transformFixed'>
                {notifications && notifications.length ? (
                  notifications.map((n, i) => {
                    let text
                    if (n.type === 'submission')
                      text = `<div class='d-flex'>
                        <img src=${submission} class='noti-icon m-2 '/>
                        <div class='noti-text'>New submission received by 
                        <strong>
                        ${
                          n.user_name
                            ? n.user_name
                            : n.email
                            ? n.email
                            : n.address.substr(0, 8) +
                              '...' +
                              n.address.substr(n.address.length - 8)
                        } 
                      </strong>
                       on request 
                       <strong>
                       ${n.title}
                       </strong>`
                    else if (n.type === 'submission accepted')
                      text = `<div class='d-flex'>
                            <img src=${submissionAccepted} class='noti-icon m-2 text-lightBlue'/>
                            <div class='noti-text'>Your submission on request 
                            <strong>
                            ${n.title}
                            </strong> is now in progress`
                    else if (n.type === 'submission disputed')
                      text = `Your submission on request 
                          <strong>
                          ${n.title}
                          </strong> has been disputed by the client`
                    else if (n.type === 'submission completed')
                      text = `<div class='d-flex'>
                    <img src=${submissionCompleted} class='noti-icon m-2 text-lightBlue'/>
                    <div class='noti-text'>Your submission on request 
                  <strong>
                  ${n.title}
                  </strong> has been completed by the client`
                    else if (n.type === 'message')
                      text = `<div class='d-flex'>
                    <img src=${chat} class='noti-icon m-2 text-lightBlue'/>
                    <div class='noti-text'>New message received on submission by 
                    <strong>
                    ${
                      n.user_name
                        ? n.user_name
                        : n.email
                        ? n.email
                        : n.address.substr(0, 8) +
                          '...' +
                          n.address.substr(n.address.length - 8)
                    } 
                        </strong>
                         on request <strong>
                         ${n.title}
                         </strong>`
                    else if (n.type === 'revision')
                      text = `<div class='d-flex'>
                    <img src=${revision} class='noti-icon m-2 text-lightBlue'/>
                    <div class='noti-text'><strong>
                  ${
                    n.user_name
                      ? n.user_name
                      : n.email
                      ? n.email
                      : n.address.substr(0, 8) +
                        '...' +
                        n.address.substr(n.address.length - 8)
                  } 
                      </strong> 
                      has requested revision on your request 
                      <strong>
                      ${n.title}
                      </strong>`
                    else if (n.type === 'new file')
                      text = `<div class='d-flex'>
                      <img src=${newFile} class='noti-icon m-2 text-lightBlue'/>
                      <div class='noti-text'>New files submitted by 
                      <strong>
                      ${
                        n.user_name
                          ? n.user_name
                          : n.email
                          ? n.email
                          : n.address.substr(0, 8) +
                            '...' +
                            n.address.substr(n.address.length - 8)
                      } 
                          </strong> on request ${n.title}`
                    else if (n.type === 'revision accepted')
                      text = `<div class='d-flex'>
                          <img src=${revisionAccepted} class='noti-icon m-2 text-lightBlue'/>
                          <div class='noti-text'>Your revision for 
                          <strong>
                          ${n.price} ZHX
                          </strong> 
                          has been ${n.type.split(' ')[1]} 
                          by client on request 
                          <strong>
                          ${n.title}
                          </strong>`
                    else if (n.type === 'revision rejected')
                      text = `<div class='d-flex'>
                          <img src=${revisionrejected} class='noti-icon m-2 text-lightBlue'/>
                          <div class='noti-text'>Your revision for 
                          <strong>
                          ${n.price} ZHX
                          </strong> 
                          has been ${n.type.split(' ')[1]} 
                          by client on request 
                          <strong>
                          ${n.title}
                          </strong>`
                    text += `<div class='text-right noti-time'>${moment(
                      n.timestamp
                    ).fromNow()}</div></div></div>`
                    return (
                      <Dropdown.Item
                        className={`${
                          n.isRead === 0 ? 'noti-h' : ''
                        } noti py-1 pl-0 pr-2 border-bottom noti-time`}
                        dangerouslySetInnerHTML={{ __html: text }}
                        key={i}
                        onClick={() => {
                          if (n.type === 'submission')
                            history.push(
                              `/projects/${n.client_request_id}/details`
                            )
                          else if (
                            n.type === 'submission accepted' ||
                            n.type === 'submission disputed' ||
                            n.type === 'submission accepted'
                          )
                            history.push(
                              `/offer/submission/${n.submission_id}/project/${n.client_request_id}/details`
                            )
                          else if (
                            n.type === 'message' ||
                            n.type === 'revision' ||
                            n.type === 'revision accepted' ||
                            n.type === 'revision rejected' ||
                            n.type === 'new file'
                          )
                            if (n.profileType === 'client')
                              history.push(
                                `/projects/${n.client_request_id}/submission/${n.submission_id}/details`
                              )
                            else
                              history.push(
                                `/offer/submission/${n.submission_id}/project/${n.client_request_id}/details`
                              )
                        }}
                      />
                    )
                  })
                ) : notifications && notifications.length === 0 ? (
                  <Dropdown.Item className=' noti py-1 border-bottom text-center'>
                    You don't have any notifications
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    className=' border-bottom'
                    style={{ padding: '.25rem 19rem' }}
                  >
                    <ClipLoader size={30} color='#0084FF' />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

            <button
              className='navbar-toggler ml-4'
              type='button'
              onClick={toggleDrawer}
            >
              <span className='navbar-toggler-icon'></span>
            </button>
          </div>
          {profile ? (
            <button
              className='btn signUpBtnTxt d-none d-lg-block ml-4 p-1'
              onClick={async () => {
                if (!profile) logout()
                else toggleProfilePreviewModal()
              }}
            >
              <img
                src={
                  profile == null || profile.user_image === null
                    ? profilePic
                    : config.apiUrl + profile.user_image
                }
                alt='dp'
                className='profilePic rounded-circle'
              />
            </button>
          ) : (
            <button
              className='btn signUpBtnTxt d-none d-lg-block ml-4'
              onClick={async () => {
                toggleLoginModal()
              }}
            >
              Login / Sign up
            </button> //web
          )}
        </nav>
        <div
          className='alert alert-primary m-0 text-lightBlue text-center font-weight-bold'
          role='alert'
        >
          The platform is in testing phase deployed on test network.
        </div>
        {!correctChain && (
          <div
            className='alert alert-warning m-0 text-warning text-center font-weight-bold'
            role='alert'
          >
            Please switch to{' '}
            <a onClick={switchChain} className='switchChain text-warning'>
              Binance Test Network{' '}
            </a>
            chain. You are currently on some other chain.
          </div>
        )}
        {showLoginModal && (
          <LoginModal
            toggleLoginModal={toggleLoginModal}
            showLoginModal={showLoginModal}
            toggleSignupModal={toggleSignupModal}
            setCorrectChain={setCorrectChain}
            connect={connect}
            connected={connected}
          />
        )}
        {showSignupModal && (
          <SignupModal
            showSignupModal={showSignupModal}
            toggleSignupModal={toggleSignupModal}
            setNumberOfNotifications={setNumberOfNotifications}
          />
        )}
      </div>
    </>
  )
}

export default Header
