import axios from 'axios'
import config from '../../config/config.json'
export class UserService {
  getNotifications = async (authToken, profileType) => {
    try {
      const resp = await axios.get(`${config.apiUrl}/requests/getNotifications/${profileType}`, {
        headers: {
          'x-access-token': authToken
        }
      })
      if (resp.data.success)
        return resp.data.result
      else
        return null
    }
    catch (error) {
      console.log('Error in getNotifications request', error)
      return null
    }
  }
  connectMetamask = async (data) => {
    try {
      console.log('Data: ', data)
      const resp = await axios.post(`${config.apiUrl}/users/signup`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return resp.data
    }
    catch (error) {
      console.log('Error in connectMetamask request', error.message)
      return null
    }
  }
  connectByEmail = (data) => {
    try {
      console.log('Data: ', data)
      const resp = axios.post(`${config.apiUrl}/users/login_signupByEmail`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return resp
    }
    catch (error) {
      console.log('Error in connectByEmail request', error.message)
      return null
    }
  }

  updateUser = (data, authToken) => {
    try {
      console.log('Data: ', data)
      const resp = axios.post(`${config.apiUrl}/users/update`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-access-token': authToken
        },
      })
      return resp
    }
    catch (error) {
      console.log('Error in updateUser request', error.message)
      return null
    }
  }

  getUser = async (authToken) => {
    try {
      const resp = await axios.get(`${config.apiUrl}/users/getUserDetail/`, {
        headers: {
          'x-access-token': authToken,
          'Content-Type': 'application/json',
        }
      })
      return resp
    }
    catch (error) {
      console.log('Error in getUser request', error.message)
      return null
    }
  }

  getZHXPrice = async () => {
    try {
      const resp = await axios.get(
        `${config.blockExplorerApiUrl}/price`
      )
      return resp
    }
    catch (error) {
      console.log('Error while fetching getZhxPrice: ', error.message)
    }
  }
}
