import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
      <div className='m-5 p-5 bg-white rounded-lg'>
        <p>
          GDPR PRIVACY NOTICE
          <br />
          Last updated 28<sup>th</sup> August 2020
        </p>
        <p>INTRODUCTION</p>
        <p>
          zerohex.market website and ZeroHex platform are trading names of
          Investpal Ltd
        </p>
        <p>
          Thank you for choosing to be part of our community at Investpal Ltd
          ("company", "we", "us", or "our"). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about our Privacy Notice ("Notice"), or our
          practices with regards to your personal information, please contact us
          at info@investpal.net
        </p>
        <p>
          Throughout this Notice we use the term "personal information" or
          "personal data" to describe information that can be associated with a
          specific person and can be used to identify that person. We do not
          consider personal information to include information that has been
          anonymized so that it does not identify an individual person.
        </p>
        <p>
          When you visit our website www.zerohex.market ("Website") and use our
          services, you trust us with your personal information. We take your
          privacy very seriously. In this Notice, we describe our privacy
          policy. We seek to explain to you in the clearest way possible what
          personal information we collect, how we use it and what rights you
          have in relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this Notice
          that you do not agree with, please discontinue use of our site and our
          services.
        </p>
        <p>
          This Notice applies to all information collected through our Site, as
          well as our app ("Apps"), and/or any related services, sales,
          marketing or events of the company (we refer to them collectively in
          this Notice as the "Sites").
          <br />
          Please read this Notice carefully as it will help you make informed
          decisions about sharing your personal information with us.
          <br />
          TABLE OF CONTENTS
        </p>
        <p>&nbsp;</p>
        <ol type='1'>
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE USE YOUR INFORMATION?</li>
          <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
          <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>DO WE USE GOOGLE MAPS?</li>
          <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
          <li>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
        </ol>
        <p>WHAT INFORMATION DO WE COLLECT?</p>
        <p>Personal information you disclose to us</p>
        <p>
          In Short:We collect personal information that you provide to us such
          as name, address, contact information, passwords and security data,
          payment information, and social media login data.
          <br />
          We collect personal information that you voluntarily provide to us
          when registering at the Sites or Apps, expressing an interest in
          obtaining information about us or our products and services, when
          participating in activities on the Sites,[(such as purchasing tokens,
          posting messages in our online forums or entering competitions,
          contests or giveaways) or otherwise contacting us.
          <br />
          The personal information that we collect depends on the context of
          your interactions with us and the Sites, the choices you make and the
          products and features you use. The personal information we COLLECT can
          include the following:
          <br />
          Name and Contact Data. We collect your first and last name, email
          address, postal address, phone number, and other similar contact data.
          <br />
          Credentials. We collect passwords, password hints, and similar
          security information used for authentication and account access.
          <br />
          Payment Data. We collect data necessary to process your payment if you
          make purchases, such as your payment instrument number (such as a
          credit card number), and the security code associated with your
          payment instrument. All payment data is stored by our payment
          processor and you should review its privacy policies and contact the
          payment processor directly to respond to your questions.
          <br />
          Social Media Login Data. We provide you with the option to register
          using social media account details, like your Facebook, Twitter or
          other social media account. If you choose to register in this way, we
          will collect the Information described in the section called "Social
          Logins"
          <br />
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <p>
          Information automatically collected through our Sites
          <br />
          In Short: Some information - such as IP address and/or browser and
          device characteristics - is collected automatically when you visit our
          Sites.
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Sites. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Sites and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Sites, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>
          Information collected through our Apps
          <br />
          In Short: We may collect information regarding your geo-location,
          mobile device, push notifications, and Facebook permissions when you
          use our apps.
        </p>
        <p>
          If you use our Apps, we may also collect the following information:
        </p>
        <p>
          • Geo-Location Information.We may request access or permission to and
          track location-based information from your mobile device, either
          continuously or while you are using our mobile application, to provide
          location-based services. If you wish to change our access or
          permissions, you may do so in your device's settings.
        </p>
        <p>
          • Mobile Device Access.We may request access or permission to certain
          features from your mobile device, including your mobile device's
          [Bluetooth, calendar, camera, contacts, microphone, reminders,
          sensors, SMS messages, social media accounts, storage,] and other
          features. If you wish to change our access or permissions, you may do
          so in your device's settings.
        </p>
        <p>
          • Mobile Device Data.We may automatically collect device information
          (such as your mobile device ID, model and manufacturer), operating
          system, version information and IP address.
        </p>
        <p>
          • Push Notifications. We may request to send you push notifications
          regarding your account or the mobile application. If you wish to
          opt-out from receiving these types of communications, you may turn
          them off in your device's settings.
        </p>
        <p>
          Information collected from other Sources
          <br />
          In Short: We may collect limited personal data from public databases,
          marketing partners, social media platforms, and other outside sources.
        </p>
        <p>
          We may obtain information about you from other sources, such as public
          databases, joint marketing partners, social media platforms, as well
          as from other third parties. Examples of the information we receive
          from other sources include: social media profile information (your
          name, gender, birthday, email, current city, state and country, user
          identification numbers for your contacts, profile picture URL and any
          other information that you choose to make public); marketing leads and
          search results and links, including paid listings (such as sponsored
          links).
        </p>
        <p>HOW DO WE USE YOUR INFORMATION?</p>
        <p>
          In Short: We process your information for purposes based on legitimate
          business interests, the fulfillment of our contract with you,
          compliance with our legal obligations, and/or your consent.
        </p>
        <p>
          We use personal information collected via theSites for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests ("Business Purposes"), in order to enter into or perform a
          contract with you ("Contractual"), with your consent ("Consent"),
          and/or for compliance with our legal obligations ("Legal Reasons"). We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
          <br />
          We use the information we collect or receive:
          <br />• To facilitate account creation and logon process [with your
          Consent]. If you choose to link your account with us to a third party
          account *(such as your Google or Facebook account), we use the
          information you allowed us to collect from those third parties to
          facilitate account creation and logon process. See the section below
          headed "Social Logins" for further information.
        </p>
        <p>
          • To send you marketing and promotional communications [for Business
          Purposes and/or with your Consent]. We and/or our third party
          marketing partners may use the personal information you send to us for
          our marketing purposes, if this is in accordance with your marketing
          preferences. You can opt-out of our marketing emails at any time (see
          the "Your Privacy Rights" below).
        </p>
        <p>
          • To send administrative information to you [for Business Purposes,
          Legal Reasons and/or possibly Contractual]. We may use your personal
          information to send you product, service and new feature information
          and/or information about changes to our terms, conditions, and
          policies.
        </p>
        <p>
          • Fulfill and manage your orders [for Contractual reasons]. We may use
          your information to fulfill and manage your orders, payments, returns,
          and exchanges made through the Sites.
        </p>
        <p>
          • To post testimonials [with your Consent]. We post testimonials on
          our Sites that may contain personal information. Prior to posting a
          testimonial, we will obtain your consent to use your name and
          testimonial. If you wish to update, or delete your testimonial, please
          contact us at info@investpal.net and be sure to include your name,
          testimonial location, and contact information.
        </p>
        <p>
          • Deliver targeted advertising to you [for our Business Purposes
          and/or with your Consent]. We may use your information to develop and
          display content and advertising (and work with third parties who do
          so) tailored to your interests and/or location and to measure its
          effectiveness.
          <br />• Administer prize draws and competitions [for our Business
          Purposes and/or with your Consent]. We may use your information to
          administer prize draws and competitions when you elect to participate
          in competitions.
        </p>
        <p>
          • Request Feedback [for our Business Purposes and/or with your
          Consent]. We may use your information to request feedback and to
          contact you about your use of our Sites.
        </p>
        <p>
          • To protect our Sites [for Business Purposes and/or Legal Reasons].
          We may use your information as part of our efforts to keep our Sites
          safe and secure (for example, for fraud monitoring and prevention).
        </p>
        <p>
          • To enable user-to-user communications [with your consent]. We may
          use your information in order to enable user-to-user communications
          with each user's consent.
        </p>
        <p>
          • To enforce our terms, conditions and policies [for Business
          Purposes, Legal Reasons and/or possibly Contractual].
        </p>
        <p>
          • To respond to legal requests and prevent harm [for Legal Reasons].
          If we receive a subpoena or other legal request, we may need to
          inspect the data we hold to determine how to respond.
        </p>
        <p>
          • For other Business Purposes. We may use your information for other
          Business Purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to
          evaluate and improve our Sites, products, services, marketing and your
          experience.
        </p>
        <p>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</p>
        <p>
          In Short:We never sell your data to any 3rd party. We only share
          information with your consent, to comply with laws, to protect your
          rights, or to fulfill business obligations.
        </p>
        <p>
          We only share and disclose your information in the following
          situations:
        </p>
        <p>
          • Compliance with Laws. We may disclose your information where we are
          legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
        </p>
        <p>
          • Vital Interests and Legal Rights. We may disclose your information
          where we believe it is necessary to investigate, prevent, or take
          action regarding potential violations of our policies, suspected
          fraud, situations involving potential threats to the safety of any
          person and illegal activities, or as evidence in litigation in which
          we are involved.
        </p>
        <p>
          • Vendors, Consultants and Other Third-Party Service Providers. We may
          share your data with third party vendors, service providers,
          contractors or agents who perform services for us or on our behalf and
          require access to such information to do that work. Examples include:
          payment processing, data analysis, email delivery, hosting services,
          customer service and marketing efforts. We may allow selected third
          parties to use tracking technology on the Sites, which will enable
          them to collect data about how you interact with the Sites over time.
          This information may be used to, among other things, analyze and track
          data, determine the popularity of certain content and better
          understand online activity. Unless described in this Notice, we do not
          share, sell, rent or trade any of your information with third parties
          for their promotional purposes.
        </p>
        <p>
          • Business Transfers. We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>
        <p>
          • Third-Party Advertisers. We may use third-party advertising
          companies to serve ads when you visit the Sites. These companies may
          use information about your visits to our Website(s) and other websites
          that are contained in web cookies and other tracking technologies in
          order to provide advertisements about goods and services of interest
          to you. [See our Cookie Notice [Hyperlink] for further information]
        </p>
        <p>
          • Affiliates. We may share your information with our affiliates, in
          which case we will require those affiliates to honor this privacy
          Notice. Affiliates include our parent company and any subsidiaries,
          joint venture partners or other companies that we control or that are
          under common control with us.
        </p>
        <p>
          • Business Partners. We may share your information with our business
          partners to offer you certain products, services or promotions.
        </p>
        <p>
          • With your Consent. We may disclose your personal information for any
          other purpose with your consent.
        </p>
        <p>
          • Other Users. When you share personal information (for example, by
          posting comments, contributions or other content to the Sites) or
          otherwise interact with public areas of the Site
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
