import React from "react";
import "./services.css";

const Services = () => {
  return (
    <>
      <center>
        <p className="services-heading primary-font-color mt-5 pt-5">
          Our Professional Services
        </p>

        <p className="services-txt primary-font-color mb-5">
          Get your work done efficiently through various useful categories and
          enable your business to achieve more.
        </p>
      </center>
    </>
  );
};

export default Services;
