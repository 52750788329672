import React, { useEffect, useState } from 'react'
import ProjectCard from '../../../components/client/project-card/project'
import Footer from '../../../components/shared/Footer/Footer'
import './AllProject.css'
import { ProjectRequestService } from '../../../http-services/client/request'
import { FadeLoader } from 'react-spinners'
import { Empty } from 'antd'
import { Link } from 'react-router-dom'
import FuzzySearch from 'fuzzy-search'
import Pagination from 'react-js-pagination'
import * as utils from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfileType } from '../../../slices/tokenSlice'

const AllProjects = () => {
  const [lstProjects, setLstProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [activePage, setActivePage] = useState(1)
  const httpProjectRequest = new ProjectRequestService()
  const authToken = useSelector(state => state.authToken)
  const profileType = useSelector(state => state.profileType)
  const email = useSelector(state => state.email)
  const wallet = useSelector(state => state.wallet)
  const toggleProfile = useSelector(state => state.toggleProfile)
  const dispatch = useDispatch()

  useEffect(async () => {
    if (!toggleProfile&& authToken && profileType !== 'client') {
      console.log(profileType)
      sessionStorage.setItem('currentTab', 1)
      dispatch(updateProfileType('client'))
    }
    await getAllProjects()
  }, [])

  const getAllProjects = async () => {
    if (authToken ) {
      try {
        let resp = await httpProjectRequest.showAllRequest(
          false,
          authToken,
          email,
          wallet
        )
        console.log('showAllRequest', resp)
        let projects = resp.data.result
        let totalRows = Math.round(projects.length / 2)
        let arr = []
        let count = 0
        for (let i = 0; i < totalRows; i++) {
          let _projects = []
          _projects.push(projects[count])
          _projects.push(projects[count + 1])
          arr.push({
            projects: _projects,
          })
          count += 2
        }
        setLoaded(true)
        setLstProjects(resp.data)
        setFilteredProjects(arr)
      } catch (error) {
        setLoaded(true)
        setLstProjects([])
        setFilteredProjects([])
      }
    } else {
      setLoaded(true)
      setLstProjects([])
      setFilteredProjects([])
    }
  }

  const closeProject = async id => {
    try {
      let isConfirmed = await utils.showConfirmationAlert(
        'Are You Sure?',
        'You want to close this project',
        'warning'
      )
      if (isConfirmed) {
        if (authToken ) {
          let resp = await httpProjectRequest.markProject(id, authToken)
          if (resp.status === 200) {
            await getAllProjects()
            utils.showSuccessAlert('Success', 'Project Closed Successfully!')
          }
        }
      }
    } catch (error) {
      utils.showErrorAlert('Whoops..', 'Cannot Close Project!')
    }
  }

  const handleSearchClick = () => {
    const searcher = new FuzzySearch(lstProjects, ['title', 'state'], {
      caseSensitive: false,
    })
    const result = searcher.search(searchValue)
    let totalRows = Math.round(result.length / 2)
    let arr = []
    let count = 0
    for (let i = 0; i < totalRows; i++) {
      let _projects = []
      _projects.push(result[count])
      _projects.push(result[count + 1])
      arr.push({
        projects: _projects,
      })
      count += 2
    }
    setFilteredProjects(arr)
  }
  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }
  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      handleSearchClick()
    }
  }
  const handlePageChange = pageNumber => {
    console.log(`active page is ${pageNumber}`)
    setActivePage(pageNumber)
  }
  const renderAllProjects = () => {
    if (loaded === true && filteredProjects.length > 0) {
      return (
        <>
          <div
            className='row'
            style={{ paddingBottom: '30px', paddingTop: '30px' }}
          >
            <div className='col-md-10'>
              <div className='input-group has-search'>
                <span className='fa fa-search form-control-feedback'></span>
                <input
                  type='text'
                  className='form-control searchField'
                  placeholder='Search'
                  onChange={onSearchChange}
                  onKeyDown={handleEnterKey}
                ></input>
                <div className='input-group-append'>
                  <button
                    className='btn btn-secondary searchFieldBtn'
                    type='button'
                    onClick={handleSearchClick}
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
            <div className='postReqBox col-lg-2 col-12 px-0 px-lg-3 d-flex justify-content-center justify-content-lg-start'>
              <Link className='btn btn-primary postBtn' to='/projects/new'>
                Post New Request
              </Link>
            </div>
          </div>
          {filteredProjects.length > 0
            ? filteredProjects.map((row, i) => {
                return (
                  <div className='row' key={i}>
                    {row.projects.map((project, index) => {
                      if (project !== undefined) {
                        return (
                          <div
                            className='col-md-6 px-0 px-lg-3 mb-4'
                            key={index}
                          >
                            <ProjectCard
                              project={project}
                              // notification={project.client_new_activity}
                              showCloseProjBtn={true}
                              closeProject={closeProject}
                            />
                          </div>
                        )
                      }
                    })}
                  </div>
                )
              })
            : ''}
        </>
      )
    } else if (loaded === true && filteredProjects.length === 0) {
      return (
        <>
          <div
            className='row'
            style={{ paddingBottom: '30px', paddingTop: '30px' }}
          >
            <div className='col-md-10'>
              <div className='input-group has-search'>
                <span className='fa fa-search form-control-feedback'></span>
                <input
                  type='text'
                  className='form-control searchField'
                  placeholder='Search'
                  onChange={onSearchChange}
                  onKeyDown={handleEnterKey}
                ></input>
                <div className='input-group-append'>
                  <button
                    className='btn btn-secondary searchFieldBtn'
                    type='button'
                    onClick={handleSearchClick}
                  >
                    <i className='fa fa-search'></i>
                  </button>
                </div>
              </div>
            </div>
            <div className='postReqBox col-lg-2 col-12 px-0 px-lg-3 d-flex justify-content-center justify-content-lg-start'>
              <Link
                className='btn btn-primary postBtn'
                // onClick={toggleRequestForm}
                to='/projects/new'
              >
                Post New Request
              </Link>
            </div>
          </div>
          <Empty />
        </>
      )
    } else {
      return (
        <FadeLoader
          css={{
            display: 'block',
            margin: '0 auto',
            borderColor: 'red',
            color: 'red',
          }}
          size={550}
        />
      )
    }
  }

  return (
    <>
      <div
        className='container-fluid'
        style={{ backgroundColor: '#F8F9FD', padding: '0px' }}
      >
        <div className='container container-main'>
          {renderAllProjects()}
          <div className='row'>
            <div className='col-md-12'>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={4}
                totalItemsCount={filteredProjects.length}
                pageRangeDisplayed={5}
                itemClass='page-item'
                linkClass='page-link'
                onChange={handlePageChange.bind(this)}
              />
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default AllProjects
